import React from 'react'

const ContactPageHeader = ({ pageHeaderText, pageHeaderImage }) => {
    return (
        <section className="bg-white">
            <div className="container mx-auto px-6 py-20 md:flex md:justify-between md:items-center">
                <div className="block w-full relative bg-cover bg-no-repeat bg-center rounded-md" style={{ backgroundImage: `url(${process.env.GATSBY_BACKEND_URL}${pageHeaderImage})`, height: "20rem" }}>
                    <div className="flex w-full bg-black z-10 opacity-60 justify-items-center align-middle" style={{ height: '20rem' }}>
                        <h1 className="text-white m-auto font-garamond text-6xl">{pageHeaderText}</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactPageHeader
