import { useQuery } from '@apollo/client'
import React from 'react'
import Layout from '../components/layout'
import FullscreenLoadingSpinner from '../components/fullscreen-loading-spinner'
import { GET_CONTACT_PAGE_DATA } from '../queries/queries'
import ContactPageHeader from '../components/contact-page-header'
import ContactPageContactBoxWrapper from '../components/contact-page-contact-box-wrapper'
import ContactPageGeneralInfo from '../components/contact-page-general-info'
import PreFooterImage from '../components/pre-footer-image'

const Contact = () => {

    const {data, loading, error} = useQuery(GET_CONTACT_PAGE_DATA)

    return (
        <>
          <FullscreenLoadingSpinner loading={loading} />
          {!loading &&
          <Layout>
              <ContactPageHeader 
                  pageHeaderText={data.contactPage.page_heading}
                  pageHeaderImage={data.contactPage.page_header_image.url}
              />
              <ContactPageContactBoxWrapper data={data} /> 
              <div className="border-t-2">
                  <ContactPageGeneralInfo info={data.contactPage.contact_page_general_address_info} />
                  <ContactPageGeneralInfo info={data.contactPage.contact_page_general_contact_info} />
              </div>
              <PreFooterImage />
          </Layout>}   
        </>
    )
}

export default Contact
