import React from 'react'
import ContactPageContactBox from './contact-page-contact-box'

const ContactPageContactBoxWrapper = ({ data }) => {
    return (
        <section className="bg-white">
            <div className="container mx-auto px-6 flex flex-row justify-around mb-10">
                <ContactPageContactBox
                    contactBoxImage={data.contactPage.enquiries_image.url}
                    contactBoxText={data.contactPage.enquiries_topic}
                    contactBoxEmail={data.contactPage.enquiries_email}
                    contactBoxTopic={data.contactPage.enquiries_topic}
                />
                <ContactPageContactBox
                    contactBoxImage={data.contactPage.products_image.url}
                    contactBoxText={data.contactPage.products_topic}
                    contactBoxEmail={data.contactPage.products_email}
                    contactBoxTopic={data.contactPage.products_topic}
                />
            </div>
        </section>
    )
}

export default ContactPageContactBoxWrapper
