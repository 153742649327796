import React from 'react'

const ContactPageContactBox = ({ contactBoxImage, contactBoxText, contactBoxEmail, contactBoxTopic }) => {
    return (
        <a href={`mailto:${contactBoxEmail}?subject=${contactBoxTopic}`}>
            <div className="block mx-2 relative bg-cover bg-no-repeat bg-center rounded-md" style={{ backgroundImage: `url(${process.env.GATSBY_BACKEND_URL}${contactBoxImage})`, height: "20rem" }}>
                <div className="flex w-full bg-black hover:bg-gray-400 transition duration-150 z-10 opacity-60 justify-items-center align-middle" style={{ height: '20rem' }}>
                    <h1 className="text-white m-auto font-garamond text-4xl mx-8">{contactBoxText}</h1>
                </div>
            </div>
        </a>
    )
}

export default ContactPageContactBox
