import React from 'react'
import ReactMarkdown from 'react-markdown'

const ContactPageGeneralInfo = ({ info }) => {
    return (
        <section>
            <div className="container mx-auto px-6 text-center my-10 leading-tight">
                <ReactMarkdown>
                    {info}
                </ReactMarkdown>
            </div>
        </section>
    )
}

export default ContactPageGeneralInfo
