import React from 'react'

import preFooterImg from '../images/Vliving_10.jpg'

const PreFooterImage = () => {
    return (
        <section className="bg-white">
            <div className="container mx-auto px-6 pb-2 pt-20 md:flex md:justify-between md:items-center">
                <div className="block w-full relative bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${preFooterImg})`, height: "10rem" }}>
                </div>
            </div>
        </section>
    )
}

export default PreFooterImage
